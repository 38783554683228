import axios from "axios";
import { getToken } from "../../components/common/userLocalStorageUtils";

const apiUrl = process.env.REACT_APP_API_URL;

export const getClientOrderDetails = async (limit, page, type, text) => {
    try {
        const res = await axios.get(`${apiUrl}/api/v1/admin/reconcile/get-all-payments?limit=${limit}&page=${page}`,{
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                token: getToken(),
            },
        })
        
        const {status, payments} = await res.data;

        if(status?.code === 200){
            return {
                list: payments.orders,
                total: payments.count
            }
        }
        return {
            list: [],
            total: 0
        };
    }
    catch (error) {
        console.error('Error in Partner:', error);
        throw error;
    }
};

export const getClientOrderPaymentDetails = async (limit, page, type, text) => {
    try {
        const res = await axios.get(`${apiUrl}/api/v1/admin/reconcile/get-orders/${text}?limit=${limit}&page=${page}`, {
            headers: {
              Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
              token: getToken(),
            },
          });

        const {status, orders, clientName, gender, total} = await res.data;

        if(status.code === 200){
            return {
                list: orders,
                total: total,
                user: {
                    name: clientName,
                    gender: gender
                }
            }
        }
        
        return { list: [], total: 0, user: null };
    }
    catch (error) {
        console.error('Error in Partner:', error);
        throw error;
    }
};

export const addReconcilePayment = async (data) =>{
    try {
        const res = await axios.post(`${apiUrl}/api/v1/admin/reconcile/add-reconcile-payment`, 
            data,
            {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                    token: getToken(),
                },
            }
        );

        const response = await res.data;

        return response;
    } catch (error) {
        console.error('Error in Partner:', error);
        if(error?.response?.data?.status?.message){
            throw new Error("Payment ID exists for another payment")
        }
        else
            throw error;
    }
};

export const getPaymentsByOrder = async (id, limit, page) =>{
    try {
        const res = await axios.get(`${apiUrl}/api/v1/admin/reconcile/payments-by-order/${id}?limit=${limit}&page=${page}`,{
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                token: getToken(),
            },
        })

        const {status, payments} = await res.data;

        if(status?.code === 200){
            return {
                list: payments.orders,
                total: payments.count
            }
        }
        return {
            list: [],
            total: 0
        };
    } catch (error) {
        console.error('Error in Partner:', error);
        throw error;
    }
}

export const updatePaymentStatusApproved = async (id, data) => {
    try {
        const res = await axios.put(`${apiUrl}/api/v1/admin/reconcile/update-status-approved/${id}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                    token: getToken(),
                },
            }
        );

        const {status, isUpdated} = await res.data;

        if(status.code === 200){
            return true;
        }
        
        return false;
    } catch (error) {
        console.error('Error in Partner:', error);
        throw error;
    }
}

export const updatePaymentStatusReconciled = async (id, data) => {
    try {
        const res = await axios.put(`${apiUrl}/api/v1/admin/reconcile/update-status-reconciled/${id}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                    token: getToken(),
                },
            }
        );

        const {status, isUpdated} = await res.data;

        if(status.code === 200){
            return true;
        }
        
        return false;
    } catch (error) {
        console.error('Error in Partner:', error);
        throw error;
    }
}

export const editPaymentInfo =  async (id, data) =>{
    try {
        const res = await axios.put(`${apiUrl}/api/v1/admin/reconcile/update-user-payment-concile/${id}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                    token: getToken(),
                },
            }
        );

        const {status, isUpdated} = await res.data;

        if(status.code === 200){
            return true;
        }
        
        return false;
    } catch (error) {
        console.error('Error in Partner:', error);
        throw error;
    }
}

export const getPaymentInfoById = async (id) =>{
    try {
        const res = await axios.get(`${apiUrl}/api/v1/admin/reconcile/payment-by-id/${id}`,
            {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                    token: getToken(),
                },
            }
        );
        
        const {status, payment} = await res.data;

        if(status?.code === 200){
            return {
                data: payment[0]
            }
        }
        return {
            data: null
        };
        
    } catch (error) {
        console.error('Error in Partner:', error);
        throw error;
    }
}