const array = [610, 437, 620, 58, 370, 338, 182, 216, 227, 275, 282];
const allowedEmails = [
    'sudha@avataarskincare.com', 
    'ankit@avataarskin.com', 
    'poojaprajapati_00016@avataarskincare.com', 
    'aman_00703@avataarskincare.com', 
    'sandeep_00162@avataarskincare.com', 
    'dikesh_00136@avataarskincare.com',
    
    'ankitchauhan@avataarskincare.com', 
    'pradumankushwaha@avataarskincare.com', 
    'vaibhav.sharma@avataarskin.com', 
    // 'intern.developer@avataarskin.com', 
    'sagar.paliwal@avataarskin.com',
    'varun.gupta@avataarskin.com',
]

const allAccessEmail = [
    'saumya@avataarskincare.com',
    'george.jacob@avataarskin.com',
]

const packagesAllowed = [
    'ankitchauhan@avataarskincare.com', 
    'pradumankushwaha@avataarskincare.com', 
    'vaibhav.sharma@avataarskin.com', 
    // 'intern.developer@avataarskin.com', 
    'sagar.paliwal@avataarskin.com',
    'varun.gupta@avataarskin.com',
]

const testingEmail = [
    'sagar.paliwal@avataarskin.com',
    'ankitchauhan@avataarskincare.com', 
    'pradumankushwaha@avataarskincare.com', 
    'vaibhav.sharma@avataarskin.com', 
]

const clientEditAccess = [
    'akul.ujjainwal@avataarskin.com',
    'geeta.cs@avataarskin.com',
    'mohit.jain@avataarskin.com',
    'rahul.arora@avataarskin.com',
    'vikas.singh@avataarskin.com',
    'sandeep.singh@avataarskin.com',
    'veenita.gera@avataarskin.com',
    'tanu.kashyap@avataarskin.com',
    'avataar0503@gmail.com',
    'himanshu.mishra@avataarskin.com',
    'vaibhav.sharma@avataarskin.com',
    'apoorva.sharma@avataarskin.com'
]

export function hasClientEditAccess (){
    const email = JSON.parse(localStorage.getItem('userData'))?.user?.email;
    return clientEditAccess.includes(email) || allAccessEmail.includes(email);
}

export function hasSuperAccess (id) {
    const email = JSON.parse(localStorage.getItem('userData'))?.user?.email;
    return allowedEmails.includes(email) || allAccessEmail.includes(email);
}

export function hasPackageAccess () {
    const email = JSON.parse(localStorage.getItem('userData'))?.user?.email;
    return packagesAllowed.includes(email) || allAccessEmail.includes(email);
}

export function testingAccess() {
    const email = JSON.parse(localStorage.getItem('userData'))?.user?.email;
    return testingEmail.includes(email);
}

export function editPaymentAccess () {
    const userData = JSON.parse(localStorage.getItem('userData'))?.user;
    const callerAccess = userData?.callerAccess;
    const email = ['vaibhav.sharma@avataarskin.com', 'manish.vaid@avataarskin.com'];
    return !(callerAccess || email.includes(userData.email))
}

export function approvedRejectPaymentAccess () {
    const email = ['vaibhav.sharma@avataarskin.com', 'manish.vaid@avataarskin.com'];
    const userData = JSON.parse(localStorage.getItem('userData'))?.user;
    return !email.includes(userData.email)
}

export function reconciledPaymentAccess () {
    const userData = JSON.parse(localStorage.getItem('userData'))?.user;
    const email = ['vaibhav.sharma@avataarskin.com', 'sumit.maurya@avataarskin.com'];
    return !email.includes(userData.email)
}
// return array.includes(id);