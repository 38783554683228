
import { Button, Box, Grid, IconButton, Paper, TextField, Typography, MenuItem, ListSubheader } from "@mui/material"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { hasAdminAccess} from '../../components/common/UserRolesConfig';
import { addReconcilePayment } from "../../store/actions/payment.action";

const STATUS = [
    {id: "SUBMITTED", label: "SUBMITTED"},
    {id: "APPROVED", label: "APPROVED"},
    {id: 'REJECTED', label: 'REJECTED'},
    {id: "RECONCILIED", label: "RECONCILIED"},
]

const userData = JSON.parse(localStorage.getItem('userData'))?.user;
const role = userData?.role;
const callerAccess = userData?.callerAccess;

const OrderPaymentProof = () => {   
    const navigate = useNavigate();
    const { id } = useParams();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        mode: "Cash",
        amount: "",
        orderDetailId: "",
        proof: [],
        files: [],
        paymentVendor: "",
        paymentID: "",
        status: "SUBMITTED",
        remarks: ""
    })

    const handleChange = (e) => {        
        const { name, value, type, files } = e.target;
                       
        let updatedFormData = { ...formData };
        const fileNames = [];
        if(type === 'file'){
            Array.from(files).forEach((file, index) => {
                fileNames.push(file.name);      
            });
        }
        
        updatedFormData = {
            ...updatedFormData,
            [name]: type === 'file' ? files : value,
            ['files']: fileNames
        };
        setFormData(updatedFormData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const addFormData = new FormData();
            addFormData.append('orderId', id);
            addFormData.append('mode', formData.mode);
            addFormData.append('amount', parseFloat(formData.amount));
            addFormData.append('orderDetailId', formData.orderDetailId);
            addFormData.append('paymentVendor', formData.paymentVendor);
            addFormData.append('paymentId', formData.paymentID);
            addFormData.append('remarks', formData.remarks);
            addFormData.append('status', formData.status);
            addFormData.append('submittedBy', userData?.email);
            Array.from(formData.proof).forEach(file => {
                addFormData.append("files", file);
            });
            const response = await addReconcilePayment(addFormData);
            
            const {status, payment} = response;

            if( status.code === 200){
                window.alert('Payment added successfully.');
                navigate('/payments-info');
            }
            else{
                window.alert('Something went wrong.')
            }
            
        } catch (error) {
            console.log(error);
            if(error?.message === 'Payment ID exists for another payment'){
                alert("Payment ID exists for another payment")
            }
            else 
                alert('An error occurred. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Paper elevation={3}
            sx={{
                padding: 4, margin: 'auto', maxWidth: 800, marginTop: '50px', borderRadius: 2,
            }}>
            <Box sx={{ position: 'relative' }}>
                <IconButton
                    color='primary'
                    onClick={() => navigate('/payments-info')}
                    sx={{ position: 'absolute', top: 0, left: 0 }}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant="h4"
                    align="center"
                    gutterBottom sx={{ color: '#0077b6', fontWeight: 'bold' }}
                >
                    Payment Proof
                </Typography>
            </Box>

            <Box component="form" marginTop={5} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <FromField
                        formData={formData}
                        handleChange={handleChange}
                    />

                    {(hasAdminAccess(role) || callerAccess) && (
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}
                                sx={{ padding: '12px', fontWeight: 'bold', fontSize: '16px' }}
                            >
                                {
                                    isSubmitting ? 'Adding' : 'Add'
                                }
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Paper>
    )
}
export default OrderPaymentProof

const FromField = ({ formData, handleChange }) => {
    const location = useLocation();
    const data = location?.state;
    
    const orderDetailIds = data.orderDetails.map(detail => detail.orderDetailId);

    return (
        <>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="Order Detail Id"
                    name="orderDetailId"
                    size="small"
                    onChange={handleChange}
                    value={formData.orderDetailId}
                >
                    {orderDetailIds.map((item) => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="Mode"
                    name="mode"
                    size="small"
                    onChange={handleChange}
                    value={formData.mode}
                >
                    <MenuItem value={'UPI'}>UPI</MenuItem>
                    <MenuItem value={'Cash'}>Cash</MenuItem>
                    <ListSubheader>EMI</ListSubheader>
                    <MenuItem value={'Savein'}>Savein</MenuItem>
                    <MenuItem value={'Zerope'}>Zerope</MenuItem>
                    <MenuItem value={'Bajaj Finance'}>Bajaj Finance</MenuItem>
                    <MenuItem value={'G Money'}>G Money</MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Amount"
                    name="amount"
                    type="number"
                    value={formData.amount}
                    onChange={handleChange}
                    required
                    size="small"
                    inputProps={{
                        min: 1
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Payment Vendor"
                    name="paymentVendor"
                    value={formData.paymentVendor}
                    onChange={handleChange}
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Payment ID"
                    name="paymentID"
                    value={formData.paymentID}
                    onChange={handleChange}
                    helperText= {formData.paymentID !== '' && "You won't be able to chnage it in future"}
                    required = {formData.mode !== 'Cash'}
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="status"
                    name="status"
                    size="small"
                    onChange={handleChange}
                    value={formData.status}
                    disabled
                >
                    {STATUS.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={12}>
                <TextField
                    required
                    fullWidth 
                    multiline 
                    rows={2} 
                    label="Remarks"   
                    name='remarks'
                    size="small"  
                    onChange={handleChange} 
                    value={formData.remarks}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <Button
                    variant="contained"
                    component="label"
                >
                    Upload File
                    <input
                        type="file"
                        name='proof'
                        accept=".png, .jpg, .jpeg, .pdf, .doc, .txt, .js"
                        onChange={handleChange}
                        hidden
                        multiple
                    />
                </Button>

                {
                    formData?.files.map((file, item)=>(
                        <div key={item}>{file}</div>
                    ))
                }
            </Grid>
        </>
    )
}