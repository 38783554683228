import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import './OrderView.style.css';

import { Pagination, Stack, FormControl, InputLabel, Select,
    MenuItem, TextField, IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
 } from "@mui/material";
 import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import dayjs from 'dayjs';
import { Search } from "@mui/icons-material";
import { getClientOrderDetails } from "../../store/actions/payment.action";
import ChangeStatus from "./Common/ChangeStatus";
import ProofViewer from "./Common/ProofViewer";

const PaymentProofInfo = () =>{
    const navigate = useNavigate()
    const limit = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [client, setClient] = useState({
        clientList: [],
        totalClient: 0,
        isLoading: false,
        totalPages: 1
    })

    const [search, setSearch] = useState({
        type: "",
        text: "",
        searchClick: false
    })

    const handlePageChange = (event, value) => {
        if (value >= 1 && value <= client.totalPages) {
          setCurrentPage(value);
        }
    };

    const getOrderDetails = async() =>{
        setClient((prevState)=>({
            ...prevState,
            isLoading: true
        }))

        try {            
            const response = await getClientOrderDetails(limit, currentPage, search.type, search.text)
            
            setClient((prevState) => ({
                    ...prevState,
                    clientList: response.list,
                    totalClient: response.total,
                    totalPages: Math.ceil(response.total/limit),
                    isLoading: false,
                }));
        } catch (error) {            
            setClient((prevState)=>({
                ...prevState,
                isLoading: false
            }))
        }
    }

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            getOrderDetails();
        }, 100);
      
        return () => {
            clearTimeout(debounceTimer);
        };

    }, [currentPage, search.searchClick, search.text])

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "30px" }}>
                <IconButton
                    color='primary'
                    onClick={() => navigate(-1)}
                    >
                    <KeyboardBackspaceIcon />
                </IconButton>
                <h3 style={{ margin: '0 auto' }}>Payment Info</h3>
            </div>
            <SearchComponent                     
                search={search}
                setSearch={setSearch}
                getData={getOrderDetails}
            />
            {
                client?.isLoading ? 
                (
                    <LoaderComponent/>
                ):
                (
                    <>
                        <OrderView
                            data={client.clientList}
                        />
                    </>
                )
            }

            <div className="incentiv-pagination" style={{ marginTop: '1rem' }}>
                <Stack spacing={3}>
                <Pagination
                    count={client.totalPages} page={currentPage} onChange={handlePageChange}
                    color="primary"
                />
                </Stack>
            </div>
        </>
    )
}

export default PaymentProofInfo;


const SearchComponent = ({
    search,
    setSearch,
    getData
  }) => {  

    const handleSearch = () => {
        if ((search.text.trim()).length < 1) {
            return;
        }
        setSearch((prevState) =>({
            ...prevState,
            "searchClick": !prevState.searchClick
        }))
        getData()
    };

    const handleChange = (event) => {
        const type = event.target.value;
        setSearch((prevState) =>({
            ...prevState,
            "text": "",
            "type": type
        }))
    };
  
    const handleSearchOnClick = (e) => {
        if ((search.text.trim()).length < 1) {
            return;
        }
        if (e.key === 'Enter') {
            e.preventDefault();  
            setSearch((prevState) =>({
                ...prevState,
                "searchClick": !prevState.searchClick
            }))
            getData()
        }
    };

    const handleTextChange = (e) => {
        const text = e.target.value
        setSearch((prevState) =>({
            ...prevState,
            "text": text
        }))
    }

    return (
      <>
        <FormControl sx={{ minWidth: 200, marginBottom: 2, marginRight: 2 }} size="small">
          <InputLabel id="demo-simple-select-standard-label">
            Filter Type
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={search.type}
            label="searchType"
            onChange={handleChange}
          >
            <MenuItem value="paymentId">Payment Id</MenuItem>
            <MenuItem value="orderId">Order Id</MenuItem>
          </Select>
        </FormControl>
  
        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          value={search.text}
          type={search.type === "contactNumber" ? "number" : "text"}
          required={true}
          onChange={handleTextChange}
          onKeyDown={handleSearchOnClick}
          InputProps={{
            endAdornment: (
              <IconButton color="primary" onClick={handleSearch}>
                <Search />
              </IconButton>
            ),
          }}
          sx={{marginBottom: 2}}
        />
      </>
    );
};

const OrderView = ({ data })=>{
    return (
       <div className="table-container"> 
            {
                data && data?.length > 0 ?
                (
                    <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow className="table-header">
                               <TableCell className="table-cell">Order Detail Id</TableCell>
                               <TableCell className="table-cell">Client Name</TableCell>
                               <TableCell className="table-cell">Client Phone</TableCell>
                               <TableCell className="table-cell">Mode</TableCell>
                               <TableCell className="table-cell">Payment Id</TableCell>
                               <TableCell className="table-cell">Payment Vendor</TableCell>
                               <TableCell className="table-cell">Payment Date</TableCell>
                               <TableCell className="table-cell">Amount</TableCell>
                               <TableCell className="table-cell">Status</TableCell>
                               <TableCell className="table-cell">Submitted By</TableCell> 
                               <TableCell className="table-cell">Approved By</TableCell> 
                               <TableCell className="table-cell">Reconciled By</TableCell> 
                               <TableCell className="table-cell">Proof</TableCell> 
                               <TableCell className="table-cell">Remarks</TableCell> 
                               <TableCell className="table-cell">Edit</TableCell> 
                            </TableRow>
                            <TableRow></TableRow>
                        </TableHead>

                        <TableBody>
                           {
                               data.map((row, index)=>(
                                   <TableRow key={index}>
                                       <TableCell className="table-cell">{row.orderDetailId}</TableCell>
                                       <TableCell className="table-cell">{row.username}</TableCell>
                                       <TableCell className="table-cell">{row.phone}</TableCell>
                                       <TableCell className="table-cell">{row.mode}</TableCell>
                                       <TableCell className="table-cell">{row.paymentId}</TableCell>
                                       <TableCell className="table-cell">{row.paymentVendor}</TableCell> 
                                       <TableCell className="table-cell">
                                            {dayjs(row?.createdAt).format('DD/MM/YYYY')}
                                        </TableCell>
                                       <TableCell className="table-cell">{row.amount}</TableCell>  
                                       <TableCell className="table-cell">{row.status}</TableCell>  
                                       <TableCell className="table-cell">{row.submittedBy}</TableCell>  
                                       <TableCell className="table-cell">{row.approvedBy}</TableCell> 
                                       <TableCell className="table-cell">{row.reconciledBy}</TableCell> 
                                       <TableCell className="table-cell">
                                            <ProofViewer files = {row.proof}/>
                                        </TableCell> 
                                       <TableCell className="table-cell">{row.remarks}</TableCell>   
                                       <TableCell className="table-cell">
                                           <ChangeStatus row={row}/>
                                       </TableCell>    
                                   </TableRow>
                               ))
                           }
                        </TableBody>
                    </Table>
                </TableContainer>
                )   :
                (
                    <div className="no-data-found">
                    <p>Data not found</p>
                  </div>
                )
            }
    </div>
    )
}