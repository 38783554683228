// export const PrescriptionTemplate = (data) =>{
//     const template =
//     `
//         <!DOCTYPE html>
// <html lang="en">
// <head>
//     <meta charset="UTF-8">
//     <meta name="viewport" content="width=device-width, initial-scale=1.0">
//     <title>Medical Prescription</title>
// 	<style>

// 		body {
//     font-family: Arial, sans-serif;
//     margin: 0;
//     padding: 0;
//     background-color: #f9f9f9;
//     width: 100vw;
//     height: 100vh;
//     overflow: hidden;
// }

// 		h3{
// 			text-align:left;
// 		}

// 		.watermark{
// 			position: fixed;
// 			top: 0;
// 			right: 0;
// 			width: 100%;
// 			height: 100%;
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			z-index: 10;
// 		}
// 		.watermark img{
// 			width: 500px;
// 			height: 270px;
// 			opacity: 0.1;
// 			z-index: -3;
// 		}
// 		.prescription-container {
//     position: relative;
//     background-color: rgb(244, 184, 175);
//     margin-left: 20px;

//     padding: 20px;
//     border-radius: 10px;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     overflow: hidden;
//     width: 79px; /* Width for A4 in pixels at 96 DPI */
// 			height: 11px; /* Height for A4 in pixels at 96 DPI */
//     box-sizing: border-box;
// }
// 		.table-section{
// 			display:flex;
// 			flex-direction: column;
// 			width: 100%;
// 			 padding-left: 20px ;
// 			padding-right: 20px;
// 		}
// 		/* Add the oval background */
// 		.prescription-container::before {
// 			content: '';
// 			position: absolute;
// 			top: -65px;
// 			left: -245px;
// 			width: 203%;
// 			height: 106%;
// 			background: white;
// 			border-radius: 123%;
// 			transform: rotate(-33deg);
// 			z-index: 0;
// 			pointer-events: none;
// 		}

// 		/* Ensure content remains above the oval */
// 		.prescription-container > * {
// 			position: relative;
// 			z-index: 1;
// 		}

// 		/* Add the logo with low opacity at the center */
// 		.prescription-container::after {
// 			content: ''; /* Placeholder for the logo */
// 			position: absolute;
// 			top: 50%; /* Center the logo vertically */
// 			left: 50%; /* Center the logo horizontally */
// 			transform: translate(-50%, -50%); /* Adjust the element to center properly */
// 			width: 150px; /* Adjust the size of the logo */
// 			height: 300px; /* Adjust the size of the logo */
// 			background-size: contain; /* Ensure the logo fits within the container */
// 			background-repeat: no-repeat; /* Prevent repetition of the logo */
// 			opacity: 1; /* Low opacity for the logo */
// 			z-index: 0; /* Place it behind the content */
// 			pointer-events: none; /* Ensure it doesn't interfere with interaction */
// 		}

// 		.top {
// 			display: flex;
// 			align-items: center;
// 			justify-content: space-between;
// 		}

// 		.header {
// 			color: white;
// 			padding: 20px;
// 			border-radius: 10px 10px 0 0;
// 		}

// 		.header .logo {
// 			margin-right: 100px;
// 		}

// 		.header .logo img {
// 			width: 150px;
// 			height: 80px;
// 			transform: scale(1.2);
// 		}

// 		.doctor-info {
// 			display: flex;
// 			flex-direction: column;
// 			text-align: right;
// 			color: black;
// 		}

// 		.header .doctor-info h1 {
// 			margin: 0;
// 			font-size: 1.8em;
// 		}

// 		.header .doctor-info p {
// 			margin: 5px 0;
// 		}

// 		.content {
// 			display: flex;
// 			margin-top: 20px;
// 		}

// 		.patient-info {
// 			width: 30%;
// 			border-right: 2px solid #3D3D3D;
// 			padding-right: 20px;
// 		}

// 		.patient-info p {
// 			font-size: 1em;
// 			margin-bottom: 10px;
// 		}

// 		.prescription {
// 			width: 100%;
// 			/* padding-left: 20px ;
// 			padding-right: 20px; */
// 		}

// 		.rx-symbol {
// 			font-size: 2em;
// 			color: #d9534f;
// 			font-weight: bold;
// 			margin: 0;
// 		}

// 		.prescription table {
// 			width: 100%;
// 			border-collapse: collapse;
// 			margin-top: 10px;
// 		}

// 		.prescription table, .prescription th, .prescription td {
// 			border: 1px solid #ddd;
// 			padding: 8px;
// 			text-align: left;
// 			/* width:100%; */
// 		}

// 		.prescription th {
// 			background-color: rgb(61, 61, 61);
// 			color: white;
// 		}
// 		/* .general-advice{
// 			padding-left: 20px;
// 		} */
// 		.prescription td{
// 			width:100%;
// 		}

// 		/* Set specific widths for each column */
// 		.prescription th:nth-child(1),
// 		.prescription td:nth-child(1) {
// 			width: 10%; /* First column width */
// 			text-align: center;
// 		}

// 		.prescription th:nth-child(2),
// 		.prescription td:nth-child(2) {
// 			width: 45%; /* Second column width */
// 		}

// 		.prescription th:nth-child(3),
// 		.prescription td:nth-child(3) {
// 			width: 45%; /* Third column width */
// 		}
// 		/* Footer */
// 		.footer {

// 			color: black;
// 			padding: 10px 20px;
// 			border-radius: 8px;
// 			position: relative;
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			flex-direction: column;
// 			margin-bottom: 20px;
// 			margin-top: 30px;
// 		}
// 		.footer .contact-info p{
// 			margin-bottom: 0;
// 			padding-bottom: 15px;
// 			line-height: 8px;
// 		}

// 		.footer .contact-info {
// 			font-size: 14px;
// 			text-align: center;
// 		}

// 		.footer .qr-code {
// 			position: absolute;
// 			right: 20px;
// 			top: 13px;
// 			background: white;
// 			border-radius: 8px;
// 		}

// 		.a4-container {
// 			width: 793.7px; /* Width for A4 in pixels at 96 DPI */
// 			height: 1122.5px; /* Height for A4 in pixels at 96 DPI */
// 			background-color: rgb(244, 184, 175); /* White background */
// 			border: 1px solid #ccc; /* Optional border for visualization */
// 			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
// 			overflow: hidden; /* Hide content overflow */
// 			display: flex;
// 			flex-direction: column;
// 			padding: 20px; /* Optional padding */
// 		}

// 		.prescription-container{
// 			background-color: rgb(244, 184, 175); ;
// 		}
// 	</style>
// </head>
// <body>
//     <div class="prescription-container">

//         <div class="header">
//             <div class="top">

//                 <div class="logo">
//                     <img src="/HeaderLogo.png" alt="Logo">
//                 </div>
//                 <div class="doctor-info">
//                     <h1>Avataar SkinRx</h1>
//                     <p>${data?.doctorName}</p>
//                     <p>Medical Registration Number: ${data?.registration}</p>
//                 </div>
//             </div>
//         </div>

//         <div class="content">
//             <div class="patient-info">
//                 <p><strong>Patient:</strong> ${data?.patientName}</p>
//                 <p><strong>Age:</strong> ${JSON.parse(data?.customerInfo)?.["Your Age"] ?? ""} years</p>
//                 <p><strong>Gender:</strong> ${JSON.parse(data?.customerInfo)?.["Gender"] ?? ""}</p>
//                 <p><strong>Date:</strong> ${data?.todayDate}</p>
//                 <p><strong>Prescription ID:</strong> ${data?.bookingId}</p>
//             </div>

//             <div class="table-section">
//                 <p class="rx-symbol">℞</p>

//                 <div class="complaint">
//                     <h3>Complaint</h3>
//                     <p>${data?.complaint}</p>
//                 </div>

//                 <div class="diagnosis">
//                     <h3>Diagnosis</h3>
//                     <p>${data?.diagnosis}</p>
//                 </div>

//                 <div class="prescription">
//                     <h3>Medicines</h3>
//                     <table>
//                         <tr>
//                             <th>*</th>
//                             <th>Name</th>
//                             <th>Instruction</th>
//                         </tr>
//                         ${
//                             data.medicines
//                                 .map(
//                                     (medicine, index) =>
//                                     `
//                                         <tr>
//                                             <td>${index + 1}</td>
//                                             <td>${medicine.name || "N/A"}</td>
//                                             <td>${medicine.quantity || "#"} units, for ${medicine.frequency} days, ${medicine.instruction}</td>
//                                         </tr>
//                                         `
//                                 )
//                             .join("")
//                         }
//                     </table>
//                 </div>

//                 <div class="prescription">
//                     <h3>Recommendation Services</h3>
//                     <table>
//                         <tr>
//                             <th>*</th>
//                             <th>Name</th>
//                             <th>Number of Sessions</th>
//                         </tr>
//                         ${
//                             data.services
//                                 .map(
//                                     (service, index) =>
//                                     `
//                                         <tr>
//                                             <td>${index + 1}</td>
//                                             <td>${service.name || "N/A"}</td>
//                                             <td>${service.numberOfSessions || "N/A"}</td>
//                                         </tr>
//                                     `
//                                 )
//                             .join("")
//                         }
//                     </table>
//                 </div>

//                 <div class="general-advice">
//                     <h3>General Advice/Notes</h3>
//                     <p>Please follow the prescribed medication and attend all scheduled sessions for the best results.</p>
//                 </div>
//             </div>
//         </div>

//         <!-- Footer Section -->
//         <div class="footer">
//             <div class="contact-info">
//                 <p>+91 9266546444, +91 9266546004</p>
//                 <p>2, Shambu Dyal Marg, Okhla Phase III, Bahapur, New Delhi, 110065</p>
//                 <p>Monday to Sunday (9am - 5pm)</p>
//             </div>
//             <div class="qr-code">
//                 <img src="/CartQR.png" alt="QR Code" width="60" height="60" /> <!-- Replace this with your QR code link -->
//             </div>
//         </div>
//     </div>
// </body>
// </html>
//     `;

//     return template
// }
export const PrescriptionTemplate = (data) => {
  const template = `
        <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Medical Prescription</title>
	<style>
	
		body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

		h3{
			text-align:left;
		}

		.watermark{
			position: fixed;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 10;
		}
		.watermark img{
			width: 500px;
			height: 270px;
			opacity: 0.1;
			z-index: -3;
		}
		.prescription-container {
    position: relative;
    background-color: rgb(244, 184, 175);
    margin-left: 20px;
	margin-right: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: calc(100% - 100px); /* Subtract desired horizontal margins from 100% width */
    height: auto; /* Adjust height as needed */
    box-sizing: border-box;
}
		.table-section{
			display:flex;
			flex-direction: column;
			width: 100%;
			 padding-left: 20px ;
			padding-right: 20px;
		}
		/* Add the oval background */
		.prescription-container::before {
			content: '';
			position: absolute;
			top: -65px;
			left: -245px;
			width: 203%;
			height: 106%;
			background: white;
			border-radius: 123%;
			transform: rotate(-33deg);
			z-index: 0;
			pointer-events: none;
		}

		/* Ensure content remains above the oval */
		.prescription-container > * {
			position: relative;
			z-index: 1;
		}

		/* Add the logo with low opacity at the center */
		.prescription-container::after {
			content: ''; /* Placeholder for the logo */
			position: absolute;
			top: 50%; /* Center the logo vertically */
			left: 50%; /* Center the logo horizontally */
			transform: translate(-50%, -50%); /* Adjust the element to center properly */
			width: 150px; /* Adjust the size of the logo */
			height: 300px; /* Adjust the size of the logo */
			background-size: contain; /* Ensure the logo fits within the container */
			background-repeat: no-repeat; /* Prevent repetition of the logo */
			opacity: 1; /* Low opacity for the logo */
			z-index: 0; /* Place it behind the content */
			pointer-events: none; /* Ensure it doesn't interfere with interaction */
		}

		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.header {
			color: white;
			padding: 20px;
			border-radius: 10px 10px 0 0;
		}

		.header .logo {
			margin-right: 100px;
		}

		.header .logo img {
			width: 150px;
			height: 80px;
			transform: scale(1.2);
		}

		.doctor-info {
			display: flex;
			flex-direction: column;
			text-align: right;
			color: black;
		}

		.header .doctor-info h1 {
			margin: 0;
			font-size: 1.8em;
		}

		.header .doctor-info p {
			margin: 5px 0;
		}

		.content {
			display: flex;
			margin-top: 20px;
		}

		.patient-info {
			width: 30%;
			border-right: 2px solid #3D3D3D;
			padding-right: 20px;
		}

		.patient-info p {
			font-size: 1em;
			margin-bottom: 10px;
		}

		.prescription {
			width: 100%;
			/* padding-left: 20px ;
			padding-right: 20px; */
		}

		.rx-symbol {
			font-size: 2em;
			color: #d9534f;
			font-weight: bold;
			margin: 0;
		}

		.prescription table {
			width: 100%;
			border-collapse: collapse;
			margin-top: 10px;
		}

		.prescription table, .prescription th, .prescription td {
			border: 1px solid #ddd;
			padding: 8px;
			text-align: left;
			/* width:100%; */
		}

		.prescription th {
			background-color: rgb(61, 61, 61);
			color: white;
		}
		/* .general-advice{
			padding-left: 20px;
		} */
		.prescription td{
			width:100%;
		}

		/* Set specific widths for each column */
		.prescription th:nth-child(1),
		.prescription td:nth-child(1) {
			width: 10%; /* First column width */
			text-align: center;
		}

		.prescription th:nth-child(2),
		.prescription td:nth-child(2) {
			width: 45%; /* Second column width */
		}

		.prescription th:nth-child(3),
		.prescription td:nth-child(3) {
			width: 45%; /* Third column width */
		}
		/* Footer */
		.footer {
			
			color: black;
			padding: 10px 20px;
			border-radius: 8px;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-bottom: 20px;
			margin-top: 30px;
		}
		.footer .contact-info p{
			margin-bottom: 0;
			padding-bottom: 15px;
			line-height: 8px;
		}

		.footer .contact-info {
			font-size: 14px;
			text-align: center;
		}

		.footer .qr-code {
			position: absolute;
			right: 20px;
			top: 13px;
			background: white;
			border-radius: 8px;
		}

		.a4-container {
			width: 793.7px; /* Width for A4 in pixels at 96 DPI */
			height: 1122.5px; /* Height for A4 in pixels at 96 DPI */
			background-color: rgb(244, 184, 175); /* White background */
			border: 1px solid #ccc; /* Optional border for visualization */
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
			overflow: hidden; /* Hide content overflow */
			display: flex;
			flex-direction: column;
			padding: 20px; /* Optional padding */
		}

		.prescription-container{
			background-color: rgb(244, 184, 175); ;
		}
	</style>
</head>
<body>
    <div class="prescription-container">
        
        <div class="header">
            <div class="top">

                <div class="logo">
                    <img src="/HeaderLogo.png" alt="Logo">
                </div>
                <div class="doctor-info">
                    <h1>Avataar SkinRx</h1>
                    <p>${data?.doctorName}</p>
                    <p>Medical Registration Number: ${data?.registration}</p>
                </div>
            </div>
        </div>
      
        <div class="content">
            <div class="patient-info">
                <p><strong>Patient:</strong> ${data?.patientName}</p>
                <p><strong>Age:</strong> ${
                  JSON.parse(data?.customerInfo)?.['Your Age'] ?? ''
                } years</p>
                <p><strong>Gender:</strong> ${
                  JSON.parse(data?.customerInfo)?.['Gender'] ?? ''
                }</p>
                <p><strong>Date:</strong> ${data?.todayDate}</p>
                <p><strong>Prescription ID:</strong> ${data?.bookingId}</p>
            </div>

            <div class="table-section">
                <p class="rx-symbol">℞</p>

                <div class="complaint">
                    <h3>Complaint</h3>
                    <p>${data?.complaint}</p>
                </div>

                <div class="diagnosis">
                    <h3>Diagnosis</h3>
                    <p>${data?.diagnosis}</p>
                </div>

                <div class="prescription">
                    <h3>Medicines</h3>
                    <table>
                        <tr>
                            <th>*</th>
                            <th>Name</th>
                            <th>Instruction</th>
                        </tr>
                        ${data.medicines
                          .map(
                            (medicine, index) =>
                              `
                                        <tr>
                                            <td>${index + 1}</td>
                                            <td>${medicine.name || 'N/A'}</td>
                                            <td>${
                                              medicine.quantity || '#'
                                            } units, for ${
                                              medicine.frequency
                                            } days, ${medicine.instruction}</td>
                                        </tr>
                                        `,
                          )
                          .join('')}           
                    </table>
                </div>

                <div class="prescription">
                    <h3>Recommendation Services</h3>
                    <table>
                        <tr>
                            <th>*</th>
                            <th>Name</th>
                            <th>Number of Sessions</th>
                        </tr>
                        ${data.services
                          .map(
                            (service, index) =>
                              `
                                        <tr>
                                            <td>${index + 1}</td>
                                            <td>${service.name || 'N/A'}</td>
                                            <td>${
                                              service.numberOfSessions || 'N/A'
                                            }</td>
                                        </tr>
                                    `,
                          )
                          .join('')}
                    </table>
                </div>

                <div class="general-advice">
                    <h3>General Advice/Notes</h3>
                    <p>Please follow the prescribed medication and attend all scheduled sessions for the best results.</p>
                </div>
            </div>
        </div>

        <!-- Footer Section -->
        <div class="footer">
            <div class="contact-info">
                <p>+91 9266546444, +91 9266546004</p>
                <p>2, Shambu Dyal Marg, Okhla Phase III, Bahapur, New Delhi, 110065</p>
                <p>Monday to Sunday (9am - 5pm)</p>
            </div>
            <div class="qr-code">
                <img src="/CartQR.png" alt="QR Code" width="60" height="60" /> <!-- Replace this with your QR code link -->
            </div>
        </div>
    </div>
</body>
</html>
    `;

  return template;
};
