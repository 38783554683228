
import { Button, ButtonGroup, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions,
    TextField,
    Grid,
    Box
 } from "@mui/material";
import { editPaymentInfo, updatePaymentStatusApproved, updatePaymentStatusReconciled } from '../../../store/actions/payment.action';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { approvedRejectPaymentAccess, editPaymentAccess, reconciledPaymentAccess } from "../../../utils/access";

const userData = JSON.parse(localStorage.getItem('userData'))?.user;
const role = userData?.role;
const callerAccess = userData?.callerAccess;

const ChangeStatus = ({row}) =>{    
    const navigate = useNavigate()
    
    const editDetails = (payment)=>{  
        navigate(`/payments-info/edit/${payment.id}`,  {state: payment})  
    }

    const editAccess = editPaymentAccess()
    const approvedRejectAccess = approvedRejectPaymentAccess()
    
    const changeStatus = async (payment, status) =>{    
        try {
            var res = false;
            switch(status) {
                case 'APPROVED':
                    res = await updatePaymentStatusApproved(payment.id, {
                        "status": status,
                        "approvedBy": userData.email
                    })
                    break;
                case 'RECONCILIED':
                    res = await updatePaymentStatusReconciled(payment.id, {
                        "status": status,
                        "reconciledBy": userData.email
                    })
                    break;
                case 'REJECTED':
                    res = await updatePaymentStatusApproved(payment.id, {
                        "status": status,
                        "approvedBy": userData.email
                    })
                    break;
                default:
                    window.alert('Choose valid status')
                    return;
            }
    
            if(res){
                window.alert('Status changed')
                window.location.reload();
            }
            else{
                window.alert('Something went wrong');
            }
        } catch (error) {
            alert('An error occurred. Please try again later.');
        }
    }
    
    return (
        <>
            <ButtonGroup   
                orientation="vertical"
                size="small"
                aria-label="Vertical button group"
                variant="contained"
            >
                <Button variant="contained"
                    color="primary"
                    disabled = {editAccess}
                    onClick={() => editDetails(row)}
                >
                    Edit
                </Button>
                 
                <Button variant="contained"
                    color="primary"
                    disabled = {approvedRejectAccess}
                    onClick={() => changeStatus(row, 'APPROVED')}
                >
                    APPROVED
                </Button>
                
                <Button variant="contained"
                    color="primary"
                    disabled = {approvedRejectAccess}
                    onClick={() => changeStatus(row, 'REJECTED')}
                >
                    REJECTED
                </Button>
                
                <ReconciledForm data={row}/>
            </ButtonGroup>
        </>
    )
}

export default ChangeStatus;


const ReconciledForm = (data)=>{      
    const reconciledAccess = reconciledPaymentAccess()  
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        reconciledAmount: data?.data?.reconciledAmount,
        transactionId: data?.data?.transactionId,
        transactionDate: data?.data?.transactionDate,
        subventionCharges: data?.data?.subventionCharges,
        gstNumber: data?.data?.gstNumber,
        reconciledRemark:data?.data?.reconciledRemark,
    })

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleChange = (e) => {        
        const { name, value} = e.target;
                       
        let updatedFormData = { ...formData };
        
        updatedFormData = {
            ...updatedFormData,
            [name]: value,
        };
        setFormData(updatedFormData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
            try {
                const response = await editPaymentInfo(data.data.id, {
                    "mode": data.data.mode,
                    "amount": parseFloat(data.data.amount),
                    "paymentVendor": data.data.paymentVendor,
                    "paymentId": data.data.paymentID,
                    "remarks": data.data.remarks,

                    "reconciledAmount": parseFloat(formData.reconciledAmount),
                    "transactionId": formData.transactionId,
                    "transactionDate": formData.transactionDate,
                    "subventionCharges": formData.subventionCharges,
                    "gstNumber": formData.gstNumber,
                    "reconciledRemark": formData.reconciledRemark,
                });
                            
                if( response){
                    window.alert('Payment updated successfully.');
                    const res = await updatePaymentStatusReconciled(data.data.id, {
                        "status": 'RECONCILIED',
                        "reconciledBy": userData.email
                    })
                    if(res){
                        window.alert('Status changed')
                        window.location.reload();
                    }
                    else{
                        window.alert('Something went wrong');
                    }
                }
                else{
                    window.alert('Something went wrong.')
                }
                
            } catch (error) {                
                alert('An error occurred. Please try again later.');
            } finally {
                setIsSubmitting(false);
            }
        };

    return (
        <>
            <Button 
                variant="contained"
                color="primary" 
                onClick={handleClickOpen}
                disabled = {reconciledAccess}
            >
                RECONCILIED
            </Button>

            <Dialog
                open={open}
                fullWidth
                onClose={handleClose}
            >
                <DialogTitle>Re Conciliation</DialogTitle>
                <DialogContent>
                    <Box component="form" marginTop={2} onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    error = {formData.reconciledAmount != data?.data?.amount}
                                    label="Actual Payment Amount"
                                    name="reconciledAmount"
                                    value={formData.reconciledAmount}
                                    size="small"
                                    required
                                    type="number"
                                    onChange={handleChange} 
                                    helperText={
                                        formData.reconciledAmount != data?.data?.amount 
                                        && `Reconciled Amount Entered not matching with amount`
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Bank Transaction ID"
                                    name="transactionId"
                                    value={formData.transactionId}
                                    size="small"
                                    required
                                    onChange={handleChange} 
                                />
                            </Grid> 
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    name="transactionDate"
                                    value={formData.transactionDate}
                                    size="small"
                                    required
                                    type="date"
                                    onChange={handleChange} 
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Subvention Charge"
                                    name="subventionCharges"
                                    value={formData.subventionCharges}
                                    size="small"
                                    onChange={handleChange} 
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Gst"
                                    name="gstNumber"
                                    value={formData.gstNumber}
                                    size="small"
                                    onChange={handleChange} 
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth 
                                    multiline 
                                    rows={2} 
                                    label="Remarks"   
                                    name='reconciledRemark'
                                    size="small"  
                                    onChange={handleChange} 
                                    value={formData.reconciledRemark}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                    sx={{ padding: '12px', fontWeight: 'bold', fontSize: '16px' }}
                                >
                                    {
                                        isSubmitting ? 'Updatting' : 'Update'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}