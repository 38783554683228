import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, MenuItem } from "@mui/material";
import axios from "axios";
import { getToken } from "../../../components/common/userLocalStorageUtils";
import { fetchCenter } from "../../../store/actions/center.action";
import { useDispatch, useSelector } from "react-redux";


const API_URL = process.env.REACT_APP_API_URL;

const WeekOffManagement = () => {
  const [selectedCenter, setSelectedCenter] = useState("");
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [therapistList, setTherapistList] = useState([]);
  const dispatch = useDispatch();

  const centers = useSelector(state => state.center?.centerList?.centers || []);
  useEffect(() => {
    if (!selectedCenter) {
      setTherapistList([]);
      return;
    }

    const fetchTherapists = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/admin/tech/therapist-by-center/${selectedCenter}`,
          { headers: { token: getToken() } }
        );

        if (response?.data?.therapists) {
          setTherapistList(response.data.therapists);
        } else {
          setTherapistList([]);
        }
      } catch (error) {
        console.error("Error fetching therapists:", error);
        alert("Failed to fetch therapists. Please try again.");
        setTherapistList([]);
      }
    };

    fetchTherapists();
  }, [selectedCenter]);

    useEffect(()=>{
      dispatch(fetchCenter());
    },[dispatch])

  const handleCityChange = (e) => {
    const city = e.target.value === "Clear" ? "" : e.target.value;
    setSelectedCenter(city);
    setSelectedTherapist("");
    if (!city) {
      setTherapistList([]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedCenter || !selectedTherapist || !selectedDate) {
      alert("Please fill out all fields.");
      return;
    }

    try {
      const payload = {
        therapistId: selectedTherapist,
        date: selectedDate,
      };
    
      const response = await axios.post(
        `${API_URL}/api/v1/admin/tech/release-week-off`,
        payload,
        { headers: { token: getToken() } }
      );
      if (response?.status === 200) {
        alert("Week off set successfully!");
        setSelectedCenter("");
        setSelectedTherapist("");
        setSelectedDate("");
        setTherapistList([]);
      } else {
        throw new Error("Failed to set week off.");
      }
    } catch (error) {
      alert(error.response?.data?.message || "Failed to set week off.");
    }
  };

  return (
    <div style={{ maxWidth: "600px", margin: "0 auto" }}>
      <h2 style={{ margin: "10px", textAlign: "center" }}>Set Therapist Week Off</h2>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              select
              label="Center"
              variant="outlined"
              value={selectedCenter}
              onChange={handleCityChange}
              fullWidth
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <MenuItem value="Clear" sx={{ fontWeight: "bold", color: "#FF0000" }}>
                Clear Filter
              </MenuItem>
              {centers.map((city) => (
                <MenuItem key={city.id} value={city.id} sx={{ color: "#333" }}>
                  {city.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              select
              label="Therapist"
              variant="outlined"
              value={selectedTherapist}
              onChange={(e) => setSelectedTherapist(e.target.value)}
              fullWidth
              disabled={!selectedCenter || therapistList.length === 0}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              {therapistList.length === 0 ? (
                <MenuItem disabled>No therapists available</MenuItem>
              ) : (
                therapistList.map((therapist) => (
                  <MenuItem key={therapist.id} value={therapist.id} sx={{ color: "#333" }}>
                    {therapist.name}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              label="Select Date"
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} mt={2} container justifyContent="center">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default WeekOffManagement;



