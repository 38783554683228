import { ImageAspectRatioRounded } from "@material-ui/icons";
import { Button, Dialog, DialogTitle, DialogActions,
    ListItem,
    ListItemAvatar,
    Avatar,
    List,ListItemText
 } from "@mui/material";

import { useState } from "react";

const ProofViewer = ({ files }) =>{        
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
        
    const handleClickOpen = () => {
        setOpen(true);
    };
      
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant="outlined" size="small" onClick={handleClickOpen}>
                View
            </Button>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>View All Proof Submitted</DialogTitle>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {files.map((email, index) => (
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <Avatar>
                                    <ImageAspectRatioRounded />
                                </Avatar>
                            </ListItemAvatar>
                            <Button onClick={()=>window.open(email)} variant="contained">View</Button>
                       </ListItem>         
                    ))}
                </List>
            </Dialog>
        </>
    )
}


export default ProofViewer;