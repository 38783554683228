import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, MenuItem } from "@mui/material";
import axios from "axios";
import { getToken } from "../../../components/common/userLocalStorageUtils";
import { fetchCenter } from "../../../store/actions/center.action";
import { useDispatch, useSelector } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

const MachineChange = () => {
  const dispatch=useDispatch();
  const [bookingId, setBookingId] = useState("");
  const [selectedCenter, setSelectedCenter] = useState("");
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState("");
  const [loading, setLoading] = useState(false);
  const centers = useSelector(state => state.center?.centerList?.centers || []);

  useEffect(() => {
    if (!selectedCenter) {
      setMachines([]);
      setSelectedMachine("");
      return;
    }

    const fetchMachines = async () => {
      setLoading(true);
      console.log(getToken());
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/admin/tech/machine-by-center/${selectedCenter}`,
          { headers: { token: getToken() } }
        );
        if (response?.status === 200) {
          setMachines(response?.data?.machines || []);
          alert("Machines fetched successfully!");
        } else {
          throw new Error("Failed to fetch machines");
        }
      } catch (error) {
        console.error("Error fetching machines:", error);
        alert("Failed to fetch machines. Please try again later.");
        setMachines([]);
      } finally {
        setLoading(false);
      }
    };
    

    fetchMachines();
  }, [selectedCenter]);

  useEffect(()=>{
    dispatch(fetchCenter());
  },[dispatch])

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!bookingId || !selectedCenter || !selectedMachine) {
      alert("Please fill all fields before submitting.");
      return;
    }
  
    try {
      const payload = {
        sessionScheduleId: bookingId,
        machineId: selectedMachine,
      };
  
      const response = await axios.post(
        `${API_URL}/api/v1/admin/tech/assign-machine`,
        payload,
        { headers: { token: getToken() } }
      );
  
      if (response?.status === 200) {
        alert("Machine assigned successfully!");
        setBookingId("");
        setSelectedCenter("");
        setSelectedMachine("");
        setMachines([]);
      } else {
        throw new Error("Failed to assign machine.");
      }
    } catch (error) {
      alert( "Failed to assign machine.");
    }
  };


  return (
    <div style={{ maxWidth: "600px", margin: "0 auto" }}>
      <h2 style={{ margin: "10px", textAlign: "center" }}>Machine Change</h2>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={8}>
            <TextField
              label="Booking ID"
              type="text"
              value={bookingId}
              onChange={(e) => setBookingId(e.target.value.replace(/\D/, ""))}
              fullWidth
              placeholder="Enter your booking ID"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              select
              label="Center"
              value={selectedCenter}
              onChange={(e) => setSelectedCenter(e.target.value)}
              fullWidth
            >
              <MenuItem value="">
                <em>Select a Center</em>
              </MenuItem>
              {centers.map((center) => (
                <MenuItem key={center.id} value={center.id}>
                  {center.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              select
              label="Machine"
              value={selectedMachine}
              onChange={(e) => setSelectedMachine(e.target.value)}
              fullWidth
              disabled={machines.length === 0}
            >
              {machines.length === 0 ? (
                <MenuItem disabled>No Machines Available</MenuItem>
              ) : (
                machines.map((machine) => (
                  <MenuItem key={machine.id} value={machine.id}>
                    {machine.name}
                  </MenuItem>
                ))
              )}
            </TextField>
          </Grid>

          <Grid item xs={12} mt={2} container justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading || !bookingId || !selectedCenter || !selectedMachine}
            >
              {loading ? "Processing..." : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default MachineChange;

