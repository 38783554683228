import React, { useState } from "react";
import { Grid, TextField, Button, MenuItem, Container, FormControl, InputLabel, Select } from "@mui/material";
import axios from "axios";
import { getToken } from "../../../components/common/userLocalStorageUtils";

const API_URL = process.env.REACT_APP_API_URL;

const StatusChange = () => {
  const [bookingId, setBookingId] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!bookingId || !status) {
      alert("Please enter a valid Booking ID and select a status.");
      return;
    }
  
    try {
      const payload = {
        sessionScheduleId: Number(bookingId),
        status: status.toUpperCase(),
      };
  
      const response = await axios.post(
        `${API_URL}/api/v1/admin/tech/change-status`,
        payload,
        {
          headers: {
            token: getToken(),
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response?.status === 200) {
        alert("Status updated successfully!");
      } else {
        throw new Error("Failed to update status.");
      }
    } catch (error) {
      alert(error.response?.data?.message || "Failed to update status. Please check the console for more details.");
    }
  
  };

  return (
    <Container maxWidth="md">
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <h2>Status Change</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              label="Booking ID"
              type="text"
              value={bookingId}
              onChange={(e) => setBookingId(e.target.value.replace(/\D/, ""))}
              fullWidth
              placeholder="Enter your booking ID"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="" disabled>
                  Select the status
                </MenuItem>
                <MenuItem value="COMPLETED">Completed</MenuItem>
                <MenuItem value="SCHEDULED">Scheduled</MenuItem>
                <MenuItem value="CANCELLED">Cancelled</MenuItem>
                <MenuItem value="PAID">Paid</MenuItem>
                <MenuItem value="SESSION_START">Session Start</MenuItem>
                <MenuItem value="SESSION_END">Session End</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container justifyContent="center">
            <Button type="submit" variant="contained" color="primary" size="large">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default StatusChange;