import React from 'react';
import ClientForm from './ClientForm.jsx';
import { ToastContainer } from 'react-toastify';

const DashboardTab = ({ feature_heading, loadComponent }) => {
  const styles = {
    toastStyles: {
      position: 'fixed',
      top: 55,
    },
    headStyles: {
      textAlign: 'center',
    },
  };
  return (
    <div>
      <ToastContainer sx={styles.toastStyles} />
      <div>
        <h1 style={styles.headStyles}>{feature_heading}</h1>
      </div>
      <div>{loadComponent}</div>
    </div>
  );
};

export default DashboardTab;
