import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";

const PrivateRoute = ({ Component }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [isAuthenticated, setIsAuthenticated] = useState(Boolean(localStorage.getItem("userData")));
    const localToken = JSON.parse(localStorage.getItem("userData"));
    const [hasCallerAccess, setHasCallerAccess] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state to ensure we wait for API call
    const location = useLocation(); // Get current route

    const verifyToken = async () => {
        try {
            const res = await axios.get(
                `${apiUrl}/api/v1/auth/is-logged-in?userId=${localToken?.user.id}&role=${localToken?.user.role}&token=${localToken?.token}`
            );
            if (res.data?.isLoggedIn) {
                setIsAuthenticated(true);
                setHasCallerAccess(localToken?.user?.callerAccess); // Set callerAccess
            } else {
                setIsAuthenticated(false);
                localStorage.removeItem("userData");
            }
        } catch (error) {
            setIsAuthenticated(false);
            localStorage.removeItem("userData");
        } finally {
            setLoading(false); // Stop loading after API call completes
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            verifyToken();
        } else {
            setLoading(false); // If not authenticated, no need to wait for API call
        }
    }, [Component]);

    // While verifying the token, show a loading indicator or return null
    if (loading) {
        return <div>Loading...</div>; // Replace with a proper loading spinner if needed
    }

    // If not authenticated, redirect to login
    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    // If the user has caller access, always redirect to /slots-info
    // if (hasCallerAccess && location.pathname !== "/slots-info") {
    //     return <Navigate to="/slots-info" />;
    // }
    // else if(hasCallerAccess && location.pathname !== "/payments-info") {
    //     return <Navigate to="/payments-info" />;
    // }

    // Render the protected component if authenticated and no redirect is needed
    return <Component />;
};

export default PrivateRoute;
