import React, { useState } from "react";
import { Grid, Box, Button, TextField } from "@mui/material";
import axios from "axios";
import { getToken } from "../../../components/common/userLocalStorageUtils";


const API_URL = process.env.REACT_APP_API_URL;

const DateTimeChange = () => {
  const [selectDateTime, setSelectDateTime] = useState("");
  const [bookingId, setBookingId] = useState("");

  const handleDateTimeChange = (e) => {
    setSelectDateTime(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectDateTime || !bookingId) {
      alert("Please enter all required fields (Booking ID and Date/Time). ");
      return;
    }

    try {
      const payload = {
        sessionScheduleId: bookingId,
        datetime: selectDateTime,
      };

      const response = await axios.post(
        `${API_URL}/api/v1/admin/tech/change-booking-datetime`,
        payload,
        {
          headers: {
            token: getToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200) {
        alert("Booking datetime updated successfully!");
      } else {
        throw new Error("Failed to update booking");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to submit data. Please try again later.");
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-center" style={{ margin: "20px" }}>
        Date and Time Change
      </h2>
      <form onSubmit={handleSubmit}>
        <Grid item xs={12} md={4} lg={3}>
          <Grid display="flex" gap={2} flexDirection={{ xs: "column", sm: "row" }}>
            <Grid item xs={12} md={8}>
              <TextField
                label="Booking ID"
                type="text"
                value={bookingId}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/, "");
                  setBookingId(value);
                }}
                onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ""))}
                fullWidth
                placeholder="Enter your booking ID"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                label="Select Date and Time"
                type="datetime-local"
                value={selectDateTime}
                onChange={handleDateTimeChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="center">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </Grid>
      </form>
    </div>
  );
};

export default DateTimeChange;
