// import { Button, TextareaAutosize, Box, IconButton } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { getToken } from '../userLocalStorageUtils';
// import { hasAdminAndSuperAdminAccess } from '../UserRolesConfig';
// import { flexbox } from '@mui/system';

// function UserRemark({ userId }) {
//     const [remark, setRemark] = useState('');
//     const [isEditing, setIsEditing] = useState(false);
//     const [isEscalated, setIsEscalated] = useState(null);
//     const role = JSON.parse(localStorage.getItem('userData'))?.user?.role; 

//     useEffect(() => {
//         const fetchRemarkDetails = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/booking/escalation-remark/${userId}`, {
//                     headers: {
//                         Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
//                         token: getToken(),
//                     },
//                 });
//                 setRemark(response?.data?.data?.remarks || remark);
//                 setIsEscalated(response?.data?.data?.is_escalated || 0);
//             } catch (error) {
//                 console.error('Error fetching remark details:', error);
//             }
//         };

//         fetchRemarkDetails();
//     }, [userId]);

//     const handleRemarkChange = (event) => {
//         setRemark(event.target.value);
//     };

//     const handleRemarkSubmit = async (event) => {
//         event.preventDefault();

//         try {
//             const adminData = JSON.parse(localStorage.getItem('userData'));
//             const adminId = adminData?.user?.id;
//             const adminName = adminData?.user?.name;

//             const body = {
//                 adminId: adminId,
//                 userId: userId,
//                 remarks: remark ? remark : '',
//                 adminName: adminName,
//                 isEscalated: isEscalated,
//             };

//             const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/booking/add-update-scalation-remark`, body, {
//                 headers: {
//                     Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
//                     token: getToken(),
//                 },
//             });

//             if (response.status === 200) {
//                 alert('Remark updated successfully!');
//                 setIsEditing(false);
//             } else {
//                 console.error('Error submitting the remark:', response);
//             }
//         } catch (error) {
//             console.error('Error during submission:', error);
//         }
//     };

//     return (
//         <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0px 3px 5px rgba(0,0,0,0.4)' }}>
//             <form style={{ paddingBottom: '15px', borderRadius: '5px', margin: '0px 20px'}} onSubmit={handleRemarkSubmit}>
//                 <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
//                     {/* <h3
//                         style={{
//                             textAlign: 'center',
//                             background: 'black',
//                             color: 'white',
//                             borderRadius: '5px',
//                             width: 'fit-content',
//                             padding: '3px 10px',
                          
//                         }}
//                     >
//                         Client Remark
//                     </h3> */}
//                 </div>

//                 {hasAdminAndSuperAdminAccess(role) && (isEditing ? (
//                     <Box style={{ padding: '0px 10px' }}>
//                         <TextareaAutosize
//                             minRows={2}
//                             maxRows={10}
//                             placeholder="Write Client remark"
//                             value={remark}
//                             onChange={handleRemarkChange}
//                             style={{
//                                 width: '100%',
//                                 padding: '10px',
//                                 border: '1px solid lightgray',
//                                 outline: 'none',
//                                 borderRadius: '8px',
//                                 resize: 'none',
//                             }}
//                         />
//                         <Box display="flex" justifyContent="flex-end" marginTop="10px" gap={3} marginRight="20px">
//                             <Button variant="contained" color="primary" type="submit" size="small">
//                                 Submit
//                             </Button>
//                             <Button variant="outlined" color="secondary" size="small" onClick={() => setIsEditing(false)}>
//                                 Cancel
//                             </Button>
//                         </Box>
//                     </Box>
//                 ) : (
//                     <Box display="flex" alignItems="center" justifyContent="space-between">
//                         <span>{remark}</span>
//                         <IconButton onClick={() => setIsEditing(true)} size="small">
//                             <EditIcon />
//                         </IconButton>
//                     </Box>
//                 ))}
//             </form>
//         </div>
//     );
// }

// export default UserRemark;

import { Button, TextareaAutosize, Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../userLocalStorageUtils';
import { hasAdminAndSuperAdminAccess } from '../UserRolesConfig';
import { flexbox } from '@mui/system';

function UserRemark({ userId }) {
    const [remark, setRemark] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isEscalated, setIsEscalated] = useState(null);
    const role = JSON.parse(localStorage.getItem('userData'))?.user?.role; 

    useEffect(() => {
        const fetchRemarkDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/booking/escalation-remark/${userId}`, {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getToken(),
                    },
                });
                setRemark(response?.data?.data?.remarks || remark);
                setIsEscalated(response?.data?.data?.is_escalated || 0);
            } catch (error) {
                console.error('Error fetching remark details:', error);
            }
        };

        fetchRemarkDetails();
    }, [userId]);



    const handleRemarkChange = (event) => {
        setRemark(event.target.value);
    };

    const handleRemarkSubmit = async (event) => {
        event.preventDefault();

        try {
            const adminData = JSON.parse(localStorage.getItem('userData'));
            const adminId = adminData?.user?.id;
            const adminName = adminData?.user?.name;

            const body = {
                adminId: adminId,
                userId: userId,
                remarks: remark ? remark : '',
                adminName: adminName,
                isEscalated: isEscalated,
            };

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/booking/add-update-scalation-remark`, body, {
                headers: {
                    Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                    token: getToken(),
                },
            });

            if (response.status === 200) {
                alert('Remark updated successfully!');
                setIsEditing(false);
            } else {
                console.error('Error submitting the remark:', response);
            }
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };

    const handleSubmit = () => {
        // You can replace this with your submit logic
        console.log("Remark submitted:", remark);
        setIsEditing(false);
        // Optionally, reset the remark after submit
        // setRemark("");
    };

    const handleCancel = () => {
        setIsEditing(false);
        setRemark(""); // Clear remark on cancel
    };

    return (
        // <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0px 3px 5px rgba(0,0,0,0.4)' }}>
            <form style={{ paddingBottom: '15px', borderRadius: '5px', margin: '0px 20px'}} onSubmit={handleRemarkSubmit}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    {/* <h3
                        style={{
                            textAlign: 'center',
                            background: 'black',
                            color: 'white',
                            borderRadius: '5px',
                            width: 'fit-content',
                            padding: '3px 10px',
                          
                        }}
                    >
                        Client Remark
                    </h3> */} 
                 </div>

                {hasAdminAndSuperAdminAccess(role) && (isEditing ? (
                    <Box style={{ padding: '0px 10px' }}>
                            <TextareaAutosize
                                    minRows={2}
                                    maxRows={10}
                                    placeholder="Write Client remark"
                                    value={remark}
                                    onChange={handleRemarkChange}
                                     style={{
                                            width: '170%', // Keeps the width responsive to the parent container
                                            // height: '50px', // Fixed height for the textarea
                                            padding: '10px', // Internal padding for better appearance
                                            border: '1px solid lightgray', // Light border for aesthetics
                                            outline: 'none', // Removes focus outline
                                            borderRadius: '8px', // Smooth, rounded corners
                                            resize: 'none', // Disables manual resizing
                                            }}
                                        />

                        
                        <Box display="flex" justifyContent="flex-end" marginTop="10px" gap={3} marginRight="20px">
                            <Button variant="contained" color="primary" type="submit" size="small"    onClick={handleSubmit}     >
                                Submit
                            </Button>
                            <Button variant="outlined" color="secondary" size="small"  onClick={handleCancel} >
                                Cancel
                            </Button>
                        </Box>
                    </Box>):
                 (
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <span>{remark}</span>
                        <IconButton onClick={() => setIsEditing(true)} size="small">
                            <EditIcon />
                        </IconButton>
                    </Box>

                )
                )}
            </form>
        // </div>
    );
}

export default UserRemark;

