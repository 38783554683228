import { Button, Box, Grid, IconButton, Paper, TextField, Typography, MenuItem } from "@mui/material"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { hasAdminAccess } from "../../../components/common/UserRolesConfig";

import {PRODUCT_CATEGORY } from "../../../constants/index";
import { getMedicationById, updateMedicationById } from "../../../store/actions/medication.action";

const ViewMedications = ()=>{
    const navigate = useNavigate();
    const { id } = useParams();
    const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        brand: "",
        medicineName: "",
        productCategory: "",
        strength: "",
        application: "",
        price: "",
        listedPrice: "",
        availableQuantity: "",
        composition: "",
        stripCount: "",
    })

    const handleChange = (e) => {        
        const { name, value } = e.target;
        
        let updatedFormData = { ...formData };
        updatedFormData = {
            ...updatedFormData,
            [name]: value,
        };
        setFormData(updatedFormData);
    };

    const getMedication = async ()=>{
        const res = await getMedicationById(id);
            if(res.status.code == 200 && res.medication){
                const medication = res.medication;
                setFormData((prevState) => ({
                    ...prevState,
                    brand: medication.brand,
                    medicineName: medication.medicineName,
                    productCategory: medication.productCategory,
                    strength: medication.strength,
                    application: medication.application,
                    price: medication.price,
                    listedPrice: medication.listedPrice,
                    availableQuantity: medication.availableQuantity,
                    composition: medication.composition,
                    stripCount: medication.stripCount
                }));
            }else if (res?.status?.code === 400) {
                alert(res?.status?.message);
            } else {
                alert('Unhandled response:', res);
            }
    }
    
    useEffect(()=>{
        try {
            getMedication()
        } catch (error) {
            alert('An error occurred. Please try again later.');
        }
    },[id])

    const handleSubmit = async (e) => {        
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const res = await updateMedicationById(id, formData)
            if(res?.status?.code == 200){
                alert(res?.status?.message);
                navigate('/medications');
            } else if (res?.status?.code === 400) {
                alert(res?.status?.message);
            } else {
                alert('Unhandled response:', res);
            }    
        } catch (error) {   
            alert('An error occurred. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    }; 

    return (
        <Paper elevation={3} 
            sx={{ 
                padding: 4, margin: 'auto', maxWidth: 800, marginTop: 2, borderRadius: 2 
            }}>
                <Box sx={{position: 'relative'}}>
                    <IconButton
                        color='primary' 
                        onClick={() => navigate('/medications')} 
                        sx={{position: 'absolute', top: 0, left: 0}}>
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant="h4" 
                        align="center" 
                        gutterBottom sx={{ color: '#0077b6', fontWeight: 'bold' }}
                    >
                        View Medication
                    </Typography>
                </Box>

                <Box component="form" marginTop={5} onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <FromField                         
                            formData={formData} 
                            handleChange={handleChange}
                        />

                        { (hasAdminAccess(role)) && (
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                    sx={{ padding: '12px', fontWeight: 'bold', fontSize: '16px' }}
                                >
                                    {
                                        isSubmitting ? 'Updatting' : 'Update'
                                    }
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
        </Paper>
    )
}
export default ViewMedications

const FromField = ({formData, handleChange})=>{
    return(
        <>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="brand"
                    name="brand"
                    value={formData.brand}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="medicine name"
                    name="medicineName"
                    value={formData.medicineName}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="product category"
                    name="productCategory"
                    value={formData.productCategory}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="strength"
                    name="strength"
                    value={formData.strength}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="application"
                    name="application"
                    value={formData.application}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="price"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    required
                    size="small"
                    type="number"
                    inputProps={{
                        min: 1
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="listed price"
                    name="listedPrice"
                    value={formData.listedPrice}
                    onChange={handleChange}
                    required
                    size="small"
                    type="number"
                    inputProps={{
                        min: 1
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="available quantity"
                    name="availableQuantity"
                    value={formData.availableQuantity}
                    onChange={handleChange}
                    required
                    size="small"
                    type="number"
                    inputProps={{
                        min: 1
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="number of template in stripe"
                    name="stripCount"
                    value={formData.stripCount}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="composition"
                    name="composition"
                    value={formData.composition}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>
        </>
    ) 
}