import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../../components/common/TableComponent/TableComponent';
import { fetchIncentiveSteps } from '../../store/actions/incentive.action';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';
import { toast, ToastContainer } from 'react-toastify';

export const ViewCheckedIncentive = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const incentiveDetails = location.state?.details;
  const { sessionId, sessionSchedulesId, partnerId, productId } =
    incentiveDetails || {};
  const incentiveSteps = useSelector((state) => state.incentive.incentiveSteps);
  const totalAllottedIncentiveAmount = incentiveSteps?.reduce(
    (total, step) => total + (step?.allottedIncentive || 0),
    0,
  );
  useEffect(() => {
    dispatch(
      fetchIncentiveSteps({
        sessionId,
        sessionSchedulesId,
        partnerId,
        productId,
      }),
    );
  }, [dispatch, sessionId, sessionSchedulesId, partnerId, productId]);

  const [editDisplay, setEditDisplay] = useState([]);

  useEffect(() => {
    setEditDisplay(
      incentiveSteps?.map((step) => ({
        display: false,
        value: step.allottedIncentive || 0,
      })),
    );
  }, [incentiveSteps]);

  const handleEditToggle = (index) => {
    setEditDisplay((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, display: !item.display } : item,
      ),
    );
  };

  const handleValueChange = (index, newValue) => {
    setEditDisplay((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, value: Number(newValue) } : item,
      ),
    );
  };

  const handleSubmit = async ({
    partner_step_incentive_id,
    amount,
    product_id,
  }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.patch(
        `${apiUrl}/api/v1/admin/partner/update-incentive/${partner_step_incentive_id}`,
        { amount: amount },
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
            token: getToken(),
          },
        },
      );

      if (response?.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error('Error during API request:', error);
    }
  };

  return (
    <div>
      <h3>Incentive Details</h3>
      <TableComponent
        data={[incentiveDetails]}
        hiddenFields={['addressId', 'orderid', 'placeId']}
      />
      <h3>All Steps with Incentive</h3>
      <p className="checked-incentive-amount">
        Total Allotted Incentive Amount:{totalAllottedIncentiveAmount}
      </p>
      {incentiveSteps && editDisplay.length > 0 && (
        <TableComponent
          data={incentiveSteps}
          hiddenFields={['image', 'lat', 'long', 'videoUrl', 'isIncentive']}
          viewButton={'img'}
          editDisplay={editDisplay}
          handleEditToggle={handleEditToggle}
          handleSubmit={handleSubmit}
          handleValueChange={handleValueChange}
        />
      )}
    </div>
  );
};
