import { Search } from "@mui/icons-material";
import { Button, Stack, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { exportZohoAppointment, generatePaymentLink, getZohoAppointment } from "../../../store/actions/zohoAppointment.action";
import DownloadIcon from '@mui/icons-material/Download';
import TableComponent from "../../../components/common/TableComponent/TableComponent";
import LoaderComponent from "../../../components/common/LoaderComponent/LoaderComponent";

const Consultations = () => {
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [zohoAppointment, setZohoAppointment] = useState({
    appointmentList: [],
    totalAppointment: 0,
    isLoading: false,
    totalPages: 1
  })
  const [search, setSearch] = useState({
    type: "",
    text: "",
    searchClick: false
  })

  const handlePageChange = (event, value) => {
    if (value >= 1 && value <= zohoAppointment.totalPages) {
      setCurrentPage(value);
    }
  };

  const getAppointmentDetails = async () => {
    setZohoAppointment((prevState) => ({
      ...prevState,
      isLoading: true
    }))
    try {
      const response = await getZohoAppointment(limit, currentPage, search.type, search.text)

      setZohoAppointment((prevState) => ({
        ...prevState,
        appointmentList: response.list,
        totalAppointment: response.total,
        totalPages: Math.ceil(response.total / limit),
        isLoading: false,
      }));

    } catch (error) {
      setZohoAppointment((prevState) => ({
        ...prevState,
        isLoading: false
      }))
    }
  }

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      getAppointmentDetails();
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [currentPage, search.searchClick, search.text])


  const exportData = async (e) => {
    e.preventDefault();
    try {
      const data = await exportZohoAppointment();

      if (data?.status.code === 200 && data?.downloadLinks) {
        const link = data.downloadLinks.csv;
        if (link) {
          window.location.href = link;
        } else {
          alert('No valid download link available');
        }
      } else {
        alert('Error occurred while generating download link');
      }
    } catch (error) {
      alert('Error occurred while generating download link');
    }
  };

  const handleVerification = async (bookingDetail) => {
    try {
      const { booking_id } = bookingDetail
      if (booking_id) {
        const data = await generatePaymentLink(booking_id);
        const { status, linkGenerated } = data;
        if (status.code == 200 && linkGenerated) {
          alert("Payment link has been sent")
        }
        else {
          alert(status.message)
        }
      } else {
        alert('Can not fetch the booking ID.');
      }

    } catch (err) {
      alert('Error generating payment link.');
    }
  }

  return <>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "30px" }}>
      <h3 style={{ margin: '0 auto' }}>Consultations</h3>
    </div>
    <SearchComponent
      search={search}
      setSearch={setSearch}
    />
    <Stack direction="row" spacing={2} mb={3} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <div>
        Total Consultations: {zohoAppointment.totalAppointment}
      </div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<DownloadIcon />}
        onClick={(e) => exportData(e)}
      >
        Export
      </Button>
    </Stack>

    {
      zohoAppointment?.isLoading ?
        (
          <LoaderComponent />
        ) :
        (
          <>
            <TableComponent
              data={zohoAppointment.appointmentList}
              hiddenFields={["id"]}
              buttonList = {
                [
                  {
                    id: 'payment_link',
                    headerText: "Payment Link",
                    text: "Payment",
                    onClick: handleVerification,
                  }
                ]
              }
            />
          </>
        )
    }
    <div className="incentiv-pagination" style={{ marginTop: '1rem' }}>
      <Stack spacing={3}>
        <Pagination
          count={zohoAppointment.totalPages} page={currentPage} onChange={handlePageChange}
          color="primary"
        />
      </Stack>
    </div>
  </>
}


export default Consultations


const SearchComponent = ({
  search,
  setSearch,
}) => {

  const handleSearch = () => {
    if ((search.text.trim()).length < 1) {
      return;
    }
    setSearch((prevState) => ({
      ...prevState,
      "searchClick": !prevState.searchClick
    }))
  };

  const handleChange = (event) => {
    const type = event.target.value;
    setSearch((prevState) => ({
      ...prevState,
      "text": "",
      "type": type
    }))
  };

  const handleSearchOnClick = (e) => {
    if ((search.text.trim()).length < 1) {
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearch((prevState) => ({
        ...prevState,
        "searchClick": !prevState.searchClick
      }))
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value
    setSearch((prevState) => ({
      ...prevState,
      "text": text
    }))
  }

  return (
    <>
      <FormControl sx={{ minWidth: 200, marginBottom: 2, marginRight: 1 }} size="small">
        <InputLabel id="demo-simple-select-standard-label">
          Filter Type
        </InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={search.type}
          label="searchType"
          onChange={handleChange}
        >
          <MenuItem value="bookingId">Booking Id</MenuItem>
          <MenuItem value="customerName">Customer Name</MenuItem>
          <MenuItem value="customerNumber">Customer Phn Number</MenuItem>
          <MenuItem value="customer_email">Customer Email</MenuItem>
          <MenuItem value="staffName">Doctor Name</MenuItem>
          <MenuItem value="staffContactNumber">Doctor Phn Number</MenuItem>
          <MenuItem value="payment_status">Payment Status</MenuItem>
          <MenuItem value="status">Status</MenuItem>
        </Select>
      </FormControl>

      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        value={search.text}
        type={["customer_contact_no", "staff_contact_number"].includes(search.type) ? "number" : "text"}
        required={true}
        onChange={handleTextChange}
        onKeyDown={handleSearchOnClick}
        InputProps={{
          endAdornment: (
            <IconButton color="primary" onClick={handleSearch}>
              <Search />
            </IconButton>
          ),
        }}
        sx={{ marginBottom: 2 }}
      />
    </>
  );
};