import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, MenuItem, Container, FormControl, InputLabel, Select } from "@mui/material";
import axios from "axios";
import { getToken } from "../../../components/common/userLocalStorageUtils";
import { fetchCenter } from "../../../store/actions/center.action";
import { useDispatch, useSelector } from "react-redux";
const API_URL = process.env.REACT_APP_API_URL;

const TherapistChange = () => {
  const [bookingId, setBookingId] = useState("");
  const [selectedCenter, setSelectedCenter] = useState("");
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [therapistList, setTherapistList] = useState([]);
const centers = useSelector(state => state.center?.centerList?.centers || []);
const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedCenter) {
      setTherapistList([]);
      return;
    }

    const fetchTherapists = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/admin/tech/therapist-by-center/${selectedCenter}`,
          { headers: { token: getToken() } }
        );
    
        if (response?.status === 200 && response?.data?.therapists) {
          setTherapistList(response.data.therapists);
        } else {
          setTherapistList([]);
          alert("No therapists found or failed to fetch therapists.");
        }
      } catch (error) {
        console.error("Error fetching therapists:", error);
        alert("Failed to fetch therapists. Please try again later.");
        setTherapistList([]);
      }
    };
    

    fetchTherapists();
  }, [selectedCenter]);
  useEffect(()=>{
        dispatch(fetchCenter());
      },[dispatch])
  

      const handleSubmit = async (event) => {
        event.preventDefault();
      
        if (!bookingId || !selectedCenter || !selectedTherapist) {
          alert("Please fill in all fields before submitting.");
          return;
        }
      
        try {
          const payload = {
            sessionScheduleId: Number(bookingId),
            therapistId: Number(selectedTherapist),
          };
      
          const response = await axios.post(
            `${API_URL}/api/v1/admin/tech/assign-therapist`,
            payload,
            {
              headers: {
                token: getToken(),
                "Content-Type": "application/json",
              },
            }
          );
    
          if (response?.status === 200) {
            alert("Therapist assigned successfully!");
          } else {
            throw new Error("Failed to assign therapist.");
          }
        } catch (error) {
          console.error("Error submitting data:", error);
          alert(error.response?.data?.message || "Failed to assign therapist. Please try again later.");
        }
      };
      
  return (
    <Container maxWidth="md">
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <h2>Assign Therapist</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              label="Booking ID"
              type="text"
              value={bookingId}
              onChange={(e) => setBookingId(e.target.value.replace(/\D/, ""))}
              fullWidth
              placeholder="Enter your booking ID"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Center</InputLabel>
              <Select
                value={selectedCenter}
                onChange={(e) => setSelectedCenter(e.target.value)}
                label="Center"
              >
                <MenuItem value="" disabled>
                  Select a Center
                </MenuItem>
                {centers.map((center) => (
                  <MenuItem key={center.id} value={center.id}>
                    {center.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" disabled={!selectedCenter || therapistList.length === 0}>
              <InputLabel>Therapist</InputLabel>
              <Select
                value={selectedTherapist}
                onChange={(e) => setSelectedTherapist(e.target.value)}
                label="Therapist"
              >
                {therapistList.length === 0 ? (
                  <MenuItem disabled>No therapists available</MenuItem>
                ) : (
                  therapistList.map((therapist) => (
                    <MenuItem key={therapist.id} value={therapist.id}>
                      {therapist.name} ({therapist.gender})
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container justifyContent="center">
            <Button type="submit" variant="contained" color="primary">
              Assign Therapist
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default TherapistChange;
