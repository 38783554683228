import {
  Grid,
  Box,
  Button,
  Paper,
  Typography,
  Dialog,
  AppBar,
  IconButton,
  Toolbar,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { useMultistepForm } from './useMultistepFrom';
import PrescriptionForm from './PrescriptionForm';
import { useState, useEffect } from 'react';
import Header from '../Header';
import { PrescriptionTemplate } from './Prescription';
import { CloseOutlined } from '@material-ui/icons';
import { createUserCart } from '../../../../store/actions/zohoAppointment.action';
import { useNavigate } from 'react-router-dom';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Form = ({ doctor, appointment }) => {
  const formatDate = (date) =>
    `${String(date.getDate()).padStart(2, '0')}/${String(
      date.getMonth() + 1,
    ).padStart(2, '0')}/${date.getFullYear()}`;

  const savedData = JSON.parse(localStorage.getItem('appointmentFormData')) || {
    patientName: appointment?.customer_name ?? '',
    patientPhoneNumber: appointment?.customer_contact_no ?? '',
    customerInfo: appointment?.customer_more_info
      ? JSON.parse(appointment.customer_more_info)
      : null,
    doctorId: doctor?.id ?? '',
    doctorName: doctor?.doctorName ?? '',
    registration: doctor?.registration ?? '',
    bookingId: appointment?.booking_id,
    serviceName: appointment?.service_name,
    todayDate: formatDate(new Date()),
    complaint: '',
    diagnosis: '',
    medicines: [
      { name: '', quantity: '', frequency: '', instruction: '', productId: '' },
    ],
    services: [
      { name: '', numberOfSessions: '', productId: '', productSessionId: '' },
    ],
    notes: '',
  };

  const [formData, setFormData] = useState(savedData);
  const [openPrescriptionTemplateView, setOpenPrescriptionTemplateView] =
    useState(false);
  const [prescriptionTemplate, setPrescriptionTemplate] = useState();
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    const newValue = type === 'checkbox' ? checked : value;

    let updatedFormData = { ...formData };
    updatedFormData = {
      ...updatedFormData,
      [name]: newValue,
    };
    setFormData(updatedFormData);
  };

  const { steps, step, currentStepIndex, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <PrescriptionForm
        formData={formData}
        handleChange={handleChange}
        setFormData={setFormData}
      />,
    ]);

  useEffect(() => {
    localStorage.setItem('appointmentFormData', JSON.stringify(formData));
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLastStep) {
      return next();
    }
    const template = PrescriptionTemplate(formData);
    setPrescriptionTemplate(template);
    setOpenPrescriptionTemplateView(true);
  };

  return (
    <>
      <Header />
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          margin: 'auto',
          maxWidth: 800,
          borderRadius: 2,
          marginTop: '100px',
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <Typography align="right" fontSize={12}>
            {currentStepIndex + 1} / {steps.length}
          </Typography>
        </Box>
        <Box component="form" marginTop={0} onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {step}
          </Grid>
          <Box
            style={{
              marginTop: '1rem',
              display: 'flex',
              gap: '.5rem',
              justifyContent: 'flex-end',
            }}
          >
            {!isFirstStep && (
              <Button
                variant="contained"
                color="primary"
                type="button"
                disabled={isFirstStep}
                onClick={back}
                sx={{ padding: '12px', fontWeight: 'bold', fontSize: '16px' }}
              >
                Back
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ padding: '12px', fontWeight: 'bold', fontSize: '16px' }}
            >
              {isLastStep ? 'Submit' : 'Next'}
            </Button>
          </Box>
        </Box>
      </Paper>
      {openPrescriptionTemplateView && prescriptionTemplate && (
        <PrescriptionTemplateView
          template={prescriptionTemplate}
          open={openPrescriptionTemplateView}
          onClose={setOpenPrescriptionTemplateView}
          formData={formData}
          appointment={appointment}
        />
      )}
    </>
  );
};

export default Form;

const PrescriptionTemplateView = ({
  template,
  open,
  onClose,
  formData,
  appointment,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const container = document.createElement('div');
      container.innerHTML = template;
      document.body.appendChild(container);

      const canvas = await html2canvas(container, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      // const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      const pdfHeight = 220;
            
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      const pdfBlob = pdf.output('blob');

      document.body.removeChild(container);
      const cartData = new FormData();
      cartData.append('doctorId', formData.doctorId);
      cartData.append(
        'phone',
        formData.patientPhoneNumber.replace(/\D/g, '').slice(-10),
      );
      cartData.append('services', JSON.stringify(formData.services));
      cartData.append('medicines', JSON.stringify(formData.medicines));
      cartData.append('prescription', pdfBlob);
      cartData.append('bookingId', formData.bookingId);
      cartData.append('paymentStatus', appointment.payment_status);

      const { cartCreated, status } = await createUserCart(cartData);

      if (status.code == 200 && cartCreated) {
        localStorage.removeItem('appointmentFormData');
        window.alert('Prescription has been sent successfully');
        navigate('/doctor/appointment');
      } else {
        window.alert(status.message);
      }
    } catch (error) {
      window.alert('SomeThing went wrong');
    } finally {
      setIsLoading(false);
    }
  };
  const handleClose = () => onClose(false);
  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Prescription
            </Typography>
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              endIcon={<CloseOutlined />}
              autoFocus
              onClick={handleClose}
            >
              Edit
            </Button>
            {isLoading ? (
              <CircularProgress size="30px" />
            ) : (
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                autoFocus
                onClick={handleSubmit}
              >
                Share
              </Button>
            )}
          </Toolbar>
        </AppBar>

        <div dangerouslySetInnerHTML={{ __html: template }} />
      </Dialog>
    </>
  );
};
