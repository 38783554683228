import React from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BallotIcon from '@mui/icons-material/Ballot';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CollectionsIcon from '@mui/icons-material/Collections';
import Booking from '../pages/Booking/Booking';
import ProductDetails from '../pages/Product/ProductDetails';
import IncentivePageLayout from '../pages/Incentive/IncentivePageLayout';
import CheckedIncentive from '../pages/Incentive/CheckedIncentive';
import UnCheckedIncentive from '../pages/Incentive/UnCheckedIncentive';
import CategoryIcon from '@mui/icons-material/Category';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Coupons from '../pages/Coupons/Coupons';
import FAQ from '../pages/FAQ/FAQ';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PreviewIcon from '@mui/icons-material/Preview';
import VirtualConsulations from '../pages/VirtualConsultations/VirtualConsultations';
import FeedIcon from '@mui/icons-material/Feed';
import UserList from '../pages/Users/UserList';
import HubIcon from '@mui/icons-material/Hub';
import CenterListing from '../pages/Center/CenterListing';
import MachineListing from '../pages/Machine/MachineListing';
import TherapistList from '../pages/Therapist/TherapistList';
import RequestList from '../pages/Request/RequestList';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Packages from '../pages/Packages/Packages';
import AdvancePayments from '../pages/AdvancePayment/AdvancePayments';
import SchedulerTabs from '../pages/Scheduler/SchedulerTabs';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BookingAnalytics from '../pages/Analytics/BookingAnalytics';
import MissedBookings from '../pages/MIssedBookings/MissedBookings';
import UpcomingBookings from '../pages/UpcomingBookings/UpcomingBookings';
import UpcomingMissedBookings from '../pages/UpcomingMissedBookings.jsx/UpcomingMissedBookings';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import Upsell from '../pages/Upsell/upsell';
import SameDayBookings from '../pages/SameDayBookings/SameDayBookings';
import TherapistSelectionPage from '../pages/TherapistAvailability/TherapistAvailabilityPage';
import SlotsInfoPage from '../pages/ClientSlots/ClientSlots';
import PaymentOrderInfo from '../pages/PaymentInfo/PaymentOrderInfo.jsx';
import PaymentProofInfo from '../pages/PaymentInfo/PaymentProofInfo.jsx';
import TherapistProductPage from '../pages/TherapistProduct/TherapistProductPage';
import MachineProductPage from '../pages/MachineProduct/MachineProductPage';
import BookingOverview from '../pages/BookingOverview/BookingOverview';
import SlotTable from '../pages/ClientSlots/ClientSlotOps';
import StyleIcon from '@mui/icons-material/Style';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
  hasPackageAccess,
  hasSuperAccess,
  testingAccess,
} from '../utils/access';
import TagList from '../pages/Tags/TagList';
import ClientList from '../pages/Clients/ClientList.jsx';
// import BookingOverview from '../pages/BookingOverview/BookingOverview';

import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import PaymentsIcon from '@mui/icons-material/Payments';
import EventAvailableTwoToneIcon from '@mui/icons-material/EventAvailableTwoTone';
import SellIcon from '@mui/icons-material/Sell';
import RuleIcon from '@mui/icons-material/Rule';
import SkincareProducts from '../pages/Products/Products';
import SkincareProductsBookings from '../pages/Products/Bookings';
import CustomerSupport from '../pages/CustomerSupport/CustomerSupport.jsx';
import OTPs from '../pages/OTP';
import { MedicationOutlined } from '@mui/icons-material';
import Medication from '../pages/Doctor/Medication/Medication.jsx';
import Doctor from '../pages/Doctor/Doctor/Doctor.jsx'
import Consultations from '../pages/Doctor/Consultations/Consultations.jsx';
import ServiceQuestions from '../pages/ServiceQuestions';
import ClientForm from '../pages/ShowBookings/ClientForm.jsx';
import DashboardTab from '../pages/ShowBookings/DashboardTab.jsx';
import ManualUpdates from '../pages/DbChanges/ManualUpdates.jsx';
// import Changes from '../pages/Changes/Changes.jsx';
// Retrieve user data from localStorage
const stringifiedUser = localStorage.getItem('userData');
const userData = stringifiedUser ? JSON.parse(stringifiedUser) : null;




// Check if the user has caller access
const hasCallerAccess =
  userData && userData.user && userData.user.callerAccess
    ? userData.user.callerAccess
    : false;

const role = userData?.user?.role;

const appRoutes = hasCallerAccess
  ? [
      // If hasCallerAccess is true, show only this route
      {
        path: '/slots-info',
        element: <SlotsInfoPage />,
        state: 'slotsInfo',
        sidebarProps: {
          displayText: 'Slots Information',
          icon: <BallotIcon />,
        },
      },
      {
        path: '/payments-info',
        element: <IncentivePageLayout />,
        state: 'paymentInfo',
        sidebarProps: {
          displayText: 'Payment Information',
          icon: <PaymentsIcon />,
        },
        child: [
          {
            index: true,
            element: <PaymentOrderInfo />,
            state: 'paymentInfo.orderdetails',
          },
          {
            path: '/payments-info/orderdetails',
            element: <PaymentOrderInfo />,
            state: 'paymentInfo.default',
            sidebarProps: {
              displayText: 'Order details',
            },
          },
          {
            path: '/payments-info/paymentinfo',
            element: <PaymentProofInfo/>,
            state: 'paymentInfo.paymentInfo',
            sidebarProps: {
              displayText: 'Payment Info',
            },
          },
        ],
      },
    ]
  : [
      // If hasCallerAccess is false, show all other routes
      {
        path: '/booking',
        element: <Booking />,
        state: 'booking',
        sidebarProps: {
          displayText: 'Booking',
          icon: <CollectionsIcon />,
        },
      },
      {
        path: '/show-bookings',
        element:  <DashboardTab feature_heading="Show Bookings" loadComponent={<ClientForm/>} />,
        state: 'showbookings',
        sidebarProps: {
          displayText: 'Show Bookings',
          icon: <PreviewIcon />,
        },
      },
      
      {
        path: '/booking-overview',
        element: <BookingOverview />,
        state: 'bookingoverview',
        sidebarProps: {
          displayText: 'Booking Overview',
          icon: <FilterNoneIcon />,
        },
      },
   
      role === 'SUPER ADMIN' && {
        path: 'manual-updates',
        element: <ManualUpdates/>,
        state: 'ManualUpdates',
        sidebarProps: {
          displayText: 'ManualUpdates',
          icon: <AssignmentIcon  />,
        },
      },
      
      {
        path: '/productdetails',
        element: <ProductDetails />,
        state: 'productdetails',
        sidebarProps: {
          displayText: 'Products',
          icon: <CategoryIcon />,
        },
      },
      {
        path: '/incentive',
        element: <IncentivePageLayout />,
        state: 'incentive',
        sidebarProps: {
          displayText: 'Incentive',
          icon: <DashboardOutlinedIcon />,
        },
        child: [
          {
            index: true,
            element: <CheckedIncentive />,
            state: 'incentive.checked',
          },
          {
            path: '/incentive/checked',
            element: <CheckedIncentive />,
            state: 'incentive.default',
            sidebarProps: {
              displayText: 'Checked Incentive',
            },
          },
          {
            path: '/incentive/uncheckedincentive',
            element: <UnCheckedIncentive />,
            state: 'incentive.uncheckedincentive',
            sidebarProps: {
              displayText: 'Unchecked Incentive',
            },
          },
        ],
      },
      {
        path: '/coupons',
        element: <Coupons />,
        state: 'coupons',
        sidebarProps: {
          displayText: 'Coupons',
          icon: <LocalActivityIcon />,
        },
      },
      role === 'SUPER ADMIN' && {
        path: '/users',
        element: <UserList />,
        state: 'userList',
        sidebarProps: {
          displayText: 'Users',
          icon: <LocalActivityIcon />,
        },
      },

      (role === 'ADMIN' || role === 'SUPER ADMIN') && 
        {
          path: '/payments-info',
          element: <IncentivePageLayout />,
          state: 'paymentInfo',
          sidebarProps: {
            displayText: 'Payment Information',
            icon: <PaymentsIcon />,
          },
          child: [
            {
              index: true,
              element: <PaymentOrderInfo />,
              state: 'paymentInfo.orderdetails',
            },
            {
              path: '/payments-info/orderdetails',
              element: <PaymentOrderInfo />,
              state: 'paymentInfo.default',
              sidebarProps: {
                displayText: 'Order details',
              },
            },
            {
              path: '/payments-info/paymentinfo',
              element: <PaymentProofInfo/>,
              state: 'paymentInfo.paymentInfo',
              sidebarProps: {
                displayText: 'Payment Info',
              },
            },
          ],
        },

      {
        path: '/virtual-consultations',
        element: <VirtualConsulations />,
        state: 'Virtual',
        sidebarProps: {
          displayText: 'Virtual Consultations',
          icon: <FeedIcon />,
        },
      },
      {
        path: '/faq',
        element: <FAQ />,
        state: 'faq',
        sidebarProps: {
          displayText: 'FAQs',
          icon: <QuestionAnswerIcon />,
        },
      },
      {
        path: '/centerlist',
        element: <CenterListing />,
        state: 'centerList',
        sidebarProps: {
          displayText: 'Center',
          icon: <HubIcon />,
        },
      },
   
      {
        path: '/machinelist',
        element: <MachineListing />,
        state: 'MachineList',
        sidebarProps: {
          displayText: 'Machine',
          icon: <FeedIcon/>,
        },
      },
      {
        path: '/machine-product',
        element: <MachineProductPage />,
        state: 'MachineProduct',
        sidebarProps: {
          displayText: 'Machine Product',
          icon: <AssignmentIcon />,
        },
      },
      
      {
        path: '/therapistlist',
        element: <TherapistList />,
        state: 'TherapistList',
        sidebarProps: {
          displayText: 'Therapist',
          icon: <LocalHospitalIcon />,
        },
      },
      {
        path: '/therapist-product',
        element: <TherapistProductPage />,
        state: 'TherapistProduct',
        sidebarProps: {
          displayText: 'Therapist Product',
          icon: <AssignmentIcon />,
        },
      },
      
      {
        path: '/allrequestlist',
        element: <RequestList />,
        state: 'dashboardRequest',
        sidebarProps: {
          displayText: 'All Request',
          icon: <RequestPageIcon />,
        },
      },
      {
        path: '/scheduler-analytics',
        element: <SchedulerTabs />,
        state: 'schedulerAnalytics',
        sidebarProps: {
          displayText: 'Scheduler Analytics',
          icon: <AnalyticsIcon />,
        },
      },
      {
        path: '/booking-analytics',
        element: <BookingAnalytics />,
        state: 'bookngAnalytics',
        sidebarProps: {
          displayText: 'Booking Analytics',
          icon: <AnalyticsIcon />,
        },
      },
      hasPackageAccess() && {
        path: '/packages',
        element: <Packages />,
        state: 'packages',
        sidebarProps: {
          displayText: 'Packages',
          icon: <BallotIcon />,
        },
      },
      {
        path: '/advance-payments',
        element: <AdvancePayments />,
        state: 'advancePayments',
        sidebarProps: {
          displayText: 'Advance Payments',
          icon: <PaymentsIcon />,
        },
      },
      // {
      //   path: '/therapist-deallocation',
      //   element: <Deallocation />,
      //   state: 'deallocation',
      //   sidebarProps: {
      //     displayText: 'Deallocation',
      //     icon: <BallotIcon />,
      //   },
      // },
      {
        path: '/missed-bookings',
        element: <MissedBookings />,
        state: 'missedBookings',
        sidebarProps: {
          displayText: 'Missed Bookings',
          icon: <RuleIcon />,
        },
      },
      // {
      //   path: '/upcoming-bookings',
      //   element: <UpcomingBookings />,
      //   state: 'upcomingBookings',
      //   sidebarProps: {
      //     displayText: 'Upcoming Bookings',
      //     icon: <BallotIcon />,
      //   },
      // },
      // {
      //   path: '/upcoming-missed-bookings',
      //   element: <UpcomingMissedBookings />,
      //   state: 'upcomingMissedBookings',
      //   sidebarProps: {
      //     displayText: 'Upcoming Missed Bookings',
      //     icon: <BallotIcon />,
      //   },
      // },
      {
        path: '/upsell',
        element: <Upsell />,
        state: 'upsell',
        sidebarProps: {
          displayText: 'Upsell',
          icon: <SellIcon />,
        },
      },
      {
        path: '/same-day-bookings',
        element: <SameDayBookings />,
        state: 'sameDayBookings',
        sidebarProps: {
          displayText: 'Same Day Bookings',
          icon: <BallotIcon />,
        },
      },
      {
        path: '/slots-ops',
        element: <SlotTable />,
        state: 'slotops',
        sidebarProps: {
          displayText: 'Slots Information Ops',
          icon: <BallotIcon />,
        },
      },
      {
        path: '/slots-info',
        element: <SlotsInfoPage />,
        state: 'slotsInfo',
        sidebarProps: {
          displayText: 'Slots Information',
          icon: <LightbulbCircleIcon />,
        },
      },
      {
        path: '/therapist-availability',
        element: <TherapistSelectionPage />,
        state: 'therapistAvailability',
        sidebarProps: {
          displayText: 'Therapist Availability',
          icon: <EventAvailableTwoToneIcon />,
        },
      },
      {
        path: '/tags',
        element: <TagList />,
        state: 'tags',
        sidebarProps: {
          displayText: 'Tags',
          icon: <StyleIcon />,
        },
      },
      {
        path: '/skincare-products',
        element: <SkincareProducts />,
        state: 'SkincareProducts',
        sidebarProps: {
          displayText: 'Skincare Products',
          icon: <ProductionQuantityLimitsIcon />,
        },
      },
      {
        path: '/skincare-products-bookings',
        element: <SkincareProductsBookings />,
        state: 'SkincareProductsBooking',
        sidebarProps: {
          displayText: 'Skincare Products Bookings',
          icon: <CollectionsIcon />,
        },
      },
      {
        path: '/clients',
        element: <ClientList />,
        state: 'clients',
        sidebarProps: {
          displayText: 'Clients',
          icon: <PeopleAltIcon />,
        },
      },
      {
        path: '/customer-support',
        element: <CustomerSupport />,
        state: '/customerSsupport',
        sidebarProps: {
          displayText: 'Customer Support',
          icon: <PeopleAltIcon />,
        },
      },
      {
        path: '/service-questions',
        element: <ServiceQuestions />,
        state: '/serviceQuestions',
        sidebarProps: {
          displayText: 'Service Questions',
          icon: <NotListedLocationIcon />,
        },
      },
      {
        path: '/otp',
        element: <OTPs />,
        state: '/otp',
        sidebarProps: {
          displayText: 'OTP',
          icon: <PeopleAltIcon />,
        },
      },
      {
        path: '/medications',
        element: <Medication/>,
        state: 'medications',
        sidebarProps: {
          displayText: 'Medications',
          icon: <MedicationOutlined />,
        },
      },
      {
        path: '/doctor',
        element: <Doctor/>,
        state: 'doctors',
        sidebarProps: {
          displayText: 'Doctor',
          icon: <MedicationOutlined />,
        },
      },
      {
        path: '/virtual-consultations/booking',
        element: <Consultations/>,
        state: 'VirtualConsultationsBookings',
        sidebarProps: {
          displayText: 'Virtual Consultations Bookings',
          icon: <FeedIcon />,
        },
      },
    ];

export default appRoutes;
